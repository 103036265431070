<template>

  <div class="page-wrapper static">


    <Header/>

      <section class="page-static">
        <div class="container">
          <div class="text-center">
              <div class="sec-title">
                  <h1 class="sec-title__title">About</h1>
              </div>
          </div>

          <h3>About His Secret Obsession</h3>
          <p>After 12 years of working with thousands of amazing women as a relationship coach, I’ve witnessed the secret male obsession to be the key to a man’s heart.</p>
          <p>Because once you understand it, you’ll be able to make the man of your choice feel a burning desire for you that’s so powerful that you’ll literally become the most important person in his life.</p>
          <p>The person he thinks about all day long.</p>
          <p>And you’ll also hear the real-life stories of women who used this same secret obsession to create the deepest love of their lives, all from seemingly “hopeless” situations and how you too can create the love you deserve.</p>
          <p>Because no matter how bad your situation seems right now... Even if you’re worried your man is losing interest after getting intimate with him too soon... Even if you’ve given up hope that your man will ever commit to you... And even if he’s been completely ignoring your calls and texts, it doesn’t matter.</p>
          <p>Because the moment you flip this secret trigger in a man’s heart (a trigger so secret that 99% of women and men don’t even know it exists) he’ll feel a surge of desire for you that goes far beyond physical attraction... and makes him see you in a whole new light.</p>
          <p>Without even realizing it, he’ll start to picture you in his life and every other woman, past and present, will fade from his mind.</p>
          <p>Because when you tap into a man’s most primal, inner desire, you literally become his OBSESSION.</p>
          <p>And even though he’s starting to feel the deepest love connection of his life, he won’t know why...</p>
          <p>Because this secret flies completely under his radar.</p>
          <p>So by now you’re probably wondering what this Secret Obsession is, right?</p>
          <p>Well, to put it simply, it’s a recently discovered primal drive that ALL MEN are powerfully influenced by without even knowing it.</p>
          <p>Every man you know agonizes over this primal drive more than anything else... Even his sex drive.</p>
          <p>In fact, this drive is so hard-wired into a man’s mind that is subconsciously controls everything he does. From the time he wakes up to the moment his head hits the pillow at night.</p>


          <h3>About The Author</h3>
          <p>James Bauer is the brainchild behind <b class="font2 primary">His Secret Obsession</b>. He’s a relationship coach who has worked with countless women from all over the globe for over twelve years. Inside his program, he teaches you various strategies for making men obsess over you through the use of certain phrases, words, and signals that helps spark the romantic senses in a man. As such, you will be able to attract men and create an amazing connection instantly.</p>
          <p>There are numerous testimonials of women who have achieved tremendous improvement in their love lives using <b class="font2 primary">His Secret Obsession</b>. So, why would you think that it won’t work for you as well? You certainly need to try it out!</p>


          <h3>Contact Us</h3>
          <p>If you have any questions, please contact us <a href="javascript:;" @click="contactMail()">here</a>.</p>

            
        </div>
      </section>
      

      
    
    <Footer/>

  </div>
</template>



<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  methods: {
    contactMail () {
        window.open(this.email, '_blank');
    }
  }
}
</script>