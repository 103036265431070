<template>

    <div class="page-wrapper">


      <Header/>

        
      <section id="slider" class="main-slider">
	      <div id="snow"></div>
        <div class="container">
          <div class="main-slider-text">
            <div class="row">
              <div class="col-12 col-xl-7">
                <div class="d-flex justify-content-center">
                  <img src="@/assets/images/slider-item.png" :alt="img_alt" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <section class="cta">
        <div class="container">
          <div class="cta__wrapper">
            <div class="row">
              <div class="col-12 col-md-8 col-lg-9">
                <h3>Get <b class="primary font2">His Secret Obsession</b> For A Special Discounted Price & <b class="primary">Learn How To Win Any Man's Heart.</b></h3>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="d-flex justify-content-end">
                  <a @click="order();" href="javascript:;">
                    <h3><span>Now Only</span><em>$</em>49</h3>
                    <p>+ Bonuses</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section class="about bg2">
        <div class="container">
          <div class="row">
            <div class="col-12 col-xl-5">
              <img src="@/assets/images/about.png" :alt="img_alt" class="img-fluid" />
            </div>
            <div class="col-12 col-xl-7">
              <p class="lead primary">What if you were to find the key to winning a man’s attention, love and devotion for the rest of your life?</p>
              <p class="lead primary">Let’s admit it. That is every woman’s dream. Unfortunately, this goal is often elusive for a majority of women since most do not know the secret techniques to use.</p>
              <p>As a woman, you ought to learn how to tap into a man’s primal drive that is usually on his subconscious mind. You will learn that it’s these trigger that determines if the man will become obsessed with you or not. You may think that if you take care of him, he’ll fall more in love with you. However, the problem is that the more you smother a man into falling in love with you, the more he will steer clear of you.</p>
              <p>This is why you ought to learn how to awaken the need for him to know the authentic you and know how to treat you like the prize you are. Luckily for you, you will be able to unravel the male mind in <b class="primary">His Secret Obsession</b>, a hugely popular program that shows you how to cultivate happiness and joy in your relationship.</p>
              <p>The author of this incredible program shares the different ways in which you can awaken the power within your man and consequently make him yearn for more of you, be fully committed to you alone, protect you, and even want to spend the rest of his life with you.</p>
            </div>
            <div class="col-12">
              <h3 class="font-weight-bold text-center mt-4">Are you ready to discover... <br class="d-none d-xl-block"><b class="primary">How to make him completely obsessed with you</b>?</h3>
            </div>
          </div>
        </div>
      </section>



      <section class="reasons">
        <div class="auto-container">
          <div class="row">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/01.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/02.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/03.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/04.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/05.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/06.jpg" :alt="img_alt" />
              </div>
            </div>
          </div>
        </div>
      </section>


      
      <section class="why">
        <div class="container">
          <div class="text-center">
            <h3>His Secret Obsession</h3>
            <h4>A highly effective program that will uncover the male primal drive that influences all men.</h4>
          </div>
          <div class="row">
            <div class="col-xl-5">
              <p>This is the drive that controls everything he does on a subconscious level. It is called the Hero Instinct and any woman who brings it out always carries the trophy home. If the woman the man is with does not bring out the Hero Instinct in him, the man will always feel a vacuum which is not a very good sign for the woman involved. However, when you pull the trigger, the man will be yours alone, and he will do everything within his power to please you.</p>
              <p>Essentially, “Hero Instinct” is a biological drive just like sex, hunger, and thirst. However, once this drive is triggered, it becomes stronger than all the three biological drives combined.</p>
            </div>
            <div class="col-xl-7">
              <div class="video-container mb-4">
                <iframe src="https://player.vimeo.com/video/524637900?h=d42dabd17b&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
              </div>
            </div>
            <div class="col-12">
              <p>The author shows you how to use a secret signal to flip on a man’s Hero Instinct. However, you have to be prepared since once you use the secret signal on any man for the first time, he will find you more enticing and alluring than any woman he has ever met.</p>
              <p>The author has shared information that men have a primal instinct to feel needed. They are secretly obsessed with feeling that they are irreplaceable. As such, they feel close to anything or anyone who brings out that feeling in them. Indeed, the feeling is the driving force behind all his thoughts and actions- and the funny thing? They are almost always clueless about it. Many men get their primal need to feel needed from their friends or at the workplace, but nothing beats a woman who knows the secret.</p>
              <p>Awakening a man’s Hero Instinct is the only surefire way to make a man fall in love with you. It makes you his primary obsession for the rest of his life. As such, becoming a man’s primary obsession does not involve you meeting his needs alone but it is all about you revealing your needs. What this means is that you will learn how to let him please you in a way that he will do everything within his power to win you over. The bottom line is that a man will never feel like he is in love if he doesn’t feel like he is earning that love.</p>
            </div>
          </div>
        </div>
      </section>


      
      <section class="reasons">
        <div class="auto-container">
          <div class="row">
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/07.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/08.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/09.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/10.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/11.jpg" :alt="img_alt" />
              </div>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-xl-2">
              <div class="reasons__single">
                <img src="@/assets/images/reasons/12.jpg" :alt="img_alt" />
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section class="getting">
        <div class="container">
          <h2 class="text-center mb-3">What <span class="font2 primary">His Secret Obsession</span> Program Will Teach You</h2>
          <div class="row">
            <div class="col-12 col-xl-6">
              <ul class="checked">
                <li>You will discover a secret signal called <b class="primary">"The Glimpse Phrase"</b> which involves giving a man a taste of your authentic self and in the process he will be left yearning to know more about you.</li>

                <li>If men lose interest in you after they realize you like them a lot, <b class="primary">"The Fascinating Signal"</b> is the key to awakening a deep attraction to him that will make him addicted to you on an emotional level. The signal is an innocent phrase that you can communicate via a text, a phone call or face to face. The signal will awaken a tug in his heart that will make you irresistible to him.</li>

                <li>You will also learn about <b class="primary">"Silent Action Signals"</b> that every woman should have and which helps accelerate his Hero Instinct into high gear. As such, you will be more alluring than any woman he has ever come across. The signals are silent because they are non-verbal. You will notice his increased interest in you when he starts catching your eye and trying very hard to make you smile.

                <li>You will also learn how to use a man’s <b class="primary">"Selective Hearing"</b> to your advantage. The trick involves swapping three simple words you use in your daily conversations.</li>

                <li><b class="primary">"I Owe You"</b> signal will turn on any man as it makes him trust you deeply and will make you his sole confidant. As such, he will even open up to you before he does to any of his male friends.

                <li><b class="primary">"Damsel in Distress Signal"</b> technique will help you learn how to tap into a man’s protective instincts which are very natural. As such, you will enjoy his undivided attention and love at all times. For instance, he may be dismissing you at all times or his attention is always fixated on the phone or other interests. The signal will switch his mode into protecting and serve gear. He will start craving for your attention over and over again.</li>
              </ul>
            </div>
            <div class="col-12 col-xl-6">
              <ul class="checked">
                <li>You will also learn about <b class="primary">"The Private Island"</b> signal that will make any man develop the feeling that you are the one. You will learn about a scientifically proven quality that determines if the man will marry you or not. The signal will keep your man long enough to trigger his <b class="primary">"Love Instinct."</b> The signal is in every woman although if you do not know how to use it, no man will stick around to experience the gut attraction that makes them want to commit.</li>

                <li><b class="primary">"The X-Ray Question"</b> will help you learn where your relationship is heading without appearing needy and clingy. You will learn his innermost thoughts about you. As such, you will refocus his whole attention on you, and you will get his undivided attention.</li>

                <li>The <b class="primary">"Ex-Back Signal"</b> entails 12 phrases that will make him come back if you have gone through a break-up and you still love him. You will be utterly irresistible to him, and he will come crawling back to you.</li>

                <li>Men sometimes leave women that they love and women rarely understand the reasons behind it. As such, the signal will make you irresistible to him, and he will think you are reading his mind.</li>

                <li>The <b class="primary">"Secret Currency"</b> of happy relationships will enable you make numerous daily to make emotional deposits in the relationship. This will help your bond will become stronger with your man.</li>

                <li>You will also discover the four words that will make any man fight for you. The man will fall into pursuit mode and do everything within his power to make you happy.</li>

                <li><b class="primary">"Relationship Material"</b> checklist is the number one signal that will cause the man stick with you for the long haul. He will be too scared to lose you.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      

      <section class="buy bg2">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-7">
              <div class="py-5">
                <h2 class="title mb-3 primary">It’s Really That Simple</h2>
                <p class="lead mb-3">And I wouldn’t make that promise if I wasn’t absolutely sure this would work for you. This program has already been proven to work for many women across the world, in all walks of life.</p>
                <h3 class="subtitle mb-4 primary">Right now you have an important decision to make...</h3>
                <p>The <b class="font2 primary">His Secret Obsession</b> eBook is a step-by-step guide that will help you avoid the uncomfortable relationship stage, which is typically filled with games, heartbreak, and frustration. It will allow you to connect on a deeper level with a man's heart, capturing his whole attention and affection for the rest of your life.</p>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-5">
              <img src="@/assets/images/about2.png" :alt="img_alt" class="img-fluid my-5 px-5">
            </div>
          </div>
        </div>
      </section>


      <section class="buy buy2">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="buy-box">
                <div class="text-center">
                  <h2>You Can Get This Amazing Information Today!</h2>
                  <p class="lead font-weight-bold primary">He Will Form An Emotional Bond With You That He Has Never Experienced Before!</p>
                  <p class="mb-0">So what are you waiting for? Get your copy of <b class="font2 primary">His Secret Obsession</b> right now and make your crush admit his feelings for you!</p>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-10 mx-auto">
                    <img src="@/assets/images/his-secret-obsession.jpg" :alt="img_alt" class="img-fluid my-5 px-5">
                  </div>
                  <div class="col-12">
                    <div class="buy-box-inner">
                      <div class="row">
                        <div class="col-12 col-lg-6 col-xl-7 text-center">
                          <h3>Special Limited Time Offer</h3>
                          <p class="lead fw-bold">An Incredible 76% Off The Original Price</p>
                          <h4>Now Only $49</h4>
                          <a @click="order();" href="javascript:;" target="tar1" rel="nofollow" class="btn order">
                            <span>Order Now</span>
                          </a>
                          <div class="col-12 mx-auto">
                            <img src="@/assets/images/cbcards.jpg" :alt="img_alt" class="img-fluid">
                          </div>
                        </div>
                        <div class="col-12 col-lg-6 col-xl-5">
                          <ul class="checked">
                            <li>Included Bonuses In Package</li>
                            <li>60-Day Money Back Guarantee</li>
                            <li>100% Customer's Satisfaction</li>
                            <li>No Shipping Cost</li>
                            <li>One Time Payment</li>
                            <li>No Monthly Fee</li>
                            <li>Instant Digital Access</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div class="col-12">
                  <div class="notes mb-0 text-center sm">
                    <p><b>CLICKBANK</b> online payment system is safe and secure. You can pay with either VISA, MasterCard or AMEX.</p>
                    <p>All communications between you and the payment server are protected and encrypted by SSL.</p>
                    <p>This guarantees that the information you inputted cannot be intercepted, providing you with peace of mind and security while making a payment.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-10 mx-auto">
              <ul class="info">
                <li>The moment you place the order, you'll be taken to a <b>Secure Checkout page</b>.</li>
                <li>Please fill in your payment details by using a <b>Credit Card</b>.</li>
                <li>After you fill in your information and confirm, you'll be able to get an email that gives you the download access.</li>
                <li>You can download the entire <b class="font2 primary">His Secret Obsession</b> package and all the bonuses right away.</li>
              </ul>
            </div>
            <div class="col-12 col-xl-10 mx-auto">
              <h3 class="my-4 text-center">With <b class="font2 primary">His Secret Obsession</b>, you will gain newfound confidence and self-assuredness that becomes a true part of you.</h3>
            </div>
          </div>
        </div>
      </section>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none" fill="transparent">
          <path d="M0 100 C -30 0 50 0 130 100 Z" fill="#ab324b"></path>
      </svg>

      <section class="money-back">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="money-badge">
                <img src="@/assets/images/money-back.png" :alt="img_alt" class="img-fluid">
              </div>
            </div>
            <div class="col-xs-12 col-lg-9">
              <h3><span>60-Day 100% Money Back Guarantee</span></h3>
              <p><b class="font2">His Secret Obsession</b> by James Bauer with a 60 Day No Questions Asked Money Back Guarantee.</p>
              <p>If within the first 60 days of receipt you are not satisfied with <b class="font2">His Secret Obsession</b> by James Bauer, you can request a refund by sending an email to the address given inside the product and we will immediately refund your entire purchase price, with no questions asked.</p>
            </div>
          </div>
        </div>
      </section>

        
      
      <Footer/>

    </div>
</template>



<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      hop_url: this.hop_url,
      base_url: this.base_url,
      img_alt: this.img_alt
    }
  },
  methods: {
    order () {
      var tar = 'tar1';
      var url = 'order/get-special-discount';
      window.open(process.env.VUE_APP_HOP_URL, tar);
      setTimeout(function(){ window.open(url, tar) }, 1000);
    },
  }
}
</script>