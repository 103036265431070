<template>

  <div class="page-wrapper static">


    <Header/>

      <section class="page-static">
        <div class="container">
          <div class="text-center">
              <div class="sec-title">
                  <h1 class="sec-title__title">Terms and Conditions ("Terms")</h1>
              </div>
          </div>

          <p>Last updated: <b>DEC 2021</b></p>

          <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using WEBSITE ADDRESS website (the "Service") operated by COMPANY NAME ("us", "we", or "our").</p>
          <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
          <p>By accessing or using the website you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>

          <h3>Accuracy</h3>
          <p>We are not responsible if information made available through this Service is not accurate, complete, or current. You acknowledge that the information is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete, or more timely sources of information. This includes but is not limited to financial, relationship, healthcare, and/or legal decisions, actions, or failures to act, or any other undertakings. Any reliance on the Service is at your own risk. </p>

          <h3>Access</h3>
          <p>Though we try to make the Service available twenty-four (24) hours a day, seven (7) days a week, except for planned down-time for maintenance, we do not warrant that the Service will be available at all times. We do not warrant that your computer, tablet, and/or smartphone will be able to access and/or support the Service. </p>

          <h3>Content</h3>
          <p>Our Service may allow you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the information you choose to share online. We cannot be held liable for the information you choose to share.</p>

          <h3>Links To Other Web Sites</h3>
          <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by our company.</p>
          <p>We have no control over these websites, and assume no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

          <h3>Updates to this Policy</h3>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If we make any changes to these Terms, we will update this date on the top of this page so you are aware of any modifications.</p>

          <h3>Contact Us</h3>
          <p>If you have any questions about this Terms, please contact us <a href="javascript:;" @click="contactMail()">here</a>.</p>

            
        </div>
      </section>
      

      
    
    <Footer/>

  </div>
</template>



<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  methods: {
    contactMail () {
        window.open(this.email, '_blank');
    }
  }
}
</script>