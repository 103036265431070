<template>

  <div class="page-wrapper static">


    <Header/>

      <section class="page-static">
        <div class="container">
          <div class="text-center">
              <div class="sec-title">
                  <h1 class="sec-title__title">Privacy Policy</h1>
              </div>
          </div>

          <p>Last updated: <b>DEC 2021</b></p>

          <p>COMPANY NAME ("us", "we", or "our") operates WEBSITE ADDRESS (the "Site"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.</p>
          <p>By using the Site, you agree to the collection and use of information in accordance with this policy.</p>

          <h3>Information Collection And Use</h3>
          <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name and/or email address ("Personal Information").</p>

          <h3>Log Data</h3>
          <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data").</p>
          <p>This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
          <p>In addition, we may use third party services such as Google Analytics to collect, monitor and analyze this data. This allows us to make better decisions to serve our users.</p>

          <h3>Advertising</h3>
          <p>We may place advertising pixels on our website. Some examles are pixels from Facebook and/or Google. This allows us to track the success of our paid advertising. This does not give us any private information, but lets us to make informed choices based on the aggregate user information. This may include aggregate demographic and viewer data.</p> 

          <h3>Communications</h3>
          <p>Should you choose to provide us with your personal information, we may use this information to contact you with newsletters, marketing or promotional materials.</p>

          <h3>Cookies</h3>
          <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p>
          <p>Like most sites on the internet, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>

          <h3>Security</h3>
          <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security. Always be careful about sharing personal information over the internet.</p>

          <h3>Embedded content from unrelated websites</h3>
          <p>Information on this site may include embedded content such as videos, images, or articles. Embedded content from other sites behaves in the exact same way as if the visitor has visited the other website.</p>
          <p>These other websites may obtain data about you, use cookies, embed additional 3rd party tracking, and monitor your interaction with that embedded content, including analysing your interaction with the embedded content if you have an account and are logged in to that site.</p>

          <h3>Changes To This Privacy Policy</h3>
          <p>This Privacy Policy is effective as of (add date) and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>
          <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>
          <p>If we make any changes to this Privacy Policy, we will update this date on the top of this page so you are aware of any modifications.</p>

          <h3>Contact Us</h3>
          <p>If you have any questions about this Privacy Policy, please contact us <a href="javascript:;" @click="contactMail()">here</a>.</p>

            
        </div>
      </section>
      

      
    
    <Footer/>

  </div>
</template>



<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  methods: {
    contactMail () {
        window.open(this.email, '_blank');
    }
  }
}
</script>