<template>
    <footer class="footer">
        <div class="footer__bg"></div>
        <div class="footer__top">
            <div class="container">
                <div class="footer-links">
                    <a :href="base_url">Home</a>
                    <a href="about" target="_blank">About</a>
                    <a href="terms-and-condition" target="_blank">Terms & Conditions</a>
                    <a href="privacy-policy" target="_blank">Privacy Policy</a>
                    <a href="javascript:;" @click="contactMail()">Contact</a>
                </div>
                <div class="footer__bottom-text">
                    <p>&copy;. All Rights Reserved. <span class="white font2">His Secret Obsession</span></p>
                </div>
                <div class="disclaimer">
                    <p><b>Disclaimer</b></p>
                    <p>Some of the links on this website are affiliate links, and that means we may earn a commission if you click or purchase through those link. The price you pay will be the same, but by using our affiliate links you are helping support our website. We genuinely appreciate your support.</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  data() {
    return {
      base_url: this.$base_url,
    }
  },
  methods: {
    contactMail () {
        window.open(this.email, '_blank');
    }
  }
}
</script>